<template>

  <div v-if="showInfo" class="class-inspection centered-column">
    <div class="class-name">{{ c.name }}</div>
    <img :src="c.img">
    <div class="class-type">{{ c.class }}</div>
    <div>Throwing constraint:</div>
    <div><em style="font-size: 1.2em;">{{ c.throwingConstraint }}</em></div>
    <div class="spacer"></div>


    <div v-if="c.foulAbility" class="ability">Missed Throw: {{ c.foulAbility }}</div>
    <div v-if="c.foulAbility" class="horiz-line"></div>
    <div class="ability">Board Ability: {{ c.boardAbility }}</div>
    <div class="horiz-line"></div>
    <div class="ability">Cornhole Ability: {{ c.cornholeAbility }}</div>
    
    <div v-if="feetForward">
      All throws +{{ feetForward }}ft closer.
    </div>

    <div class="footer">
      <div class="button" @click="showInfo=false">Back</div>
      <div class="button" v-if="confirmButton"
        @click="$emit('confirm'); showInfo=false">
        {{ confirmButton }}
      </div>
    </div>
  </div>
  
  <div class="class button" :class="{ selected }" @click="click">
    <img :src="c.img">
    <div>{{ c.class }}</div>
    <div class="info" @click.stop="showInfo = true">?</div>
    <div v-if="feetForward" class="feet" @click.stop="showInfo = true">+{{ feetForward }}ft</div>
  </div>
</template>

<script>
  
export default {
  name: "ClassButton",
  props: {
    c: Object,
    confirmButton: String,
    selected: Boolean,
    infoOnClick: Boolean,
  },
  emits: ["confirm"],
  data() {
    return {
      showInfo: false,
    }
  },
  computed: {
    feetForward() {
      return this.$root.playerToFeetForward[this.c.class]
    },
    status() {
      return this.$root.playerToStatus[this.c.class]
    },
  },
  methods: {
    click() {
      if (this.infoOnClick) {
        this.showInfo = true
      } else {
        this.$emit('confirm')
      }
    }
  },
}
</script>
<style>
.class-inspection {
  width: 100%;
  height: 100%;
  top: 0;
  font-size: 1.2rem;
  padding: 1rem;
  box-sizing: border-box;
  position: fixed;
  background: white;
  z-index: 10;
}
.class-inspection .class-name {
  font-size: 2rem;
  width: 100%;
}
.class-inspection .class-type {
  font-size: 3rem;
}
.class-inspection .ability {
  margin: 2rem 0;
  font-size: 1.2rem;
}
.class-inspection .horiz-line {
  border: 0.1rem solid black;
  width: 50%;
}

.class.button {
  height: initial;
  border-radius: 1rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5rem;
  position: relative;
}
.class.button.selected {
  border-color: gold;
  color: rgb(124, 106, 0);
  box-shadow: 0 0.2rem 0.25rem 0.05rem rgba(255, 221, 0, 0.476);
}
.class.button img {
  width: 70%;
}
.class.button .info {
  border: 0.1rem solid black;
  border-radius: 50%;
  font-size: 1rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.class.button .feet {
  font-size: 1rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}
</style>